import type { FC } from 'react';
import { Table, TableBody } from '@mui/material';
import { SubscriptionModel, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import parse from 'html-react-parser';
import { SubscriptionGroupResource } from 'src/common/types';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface WelcomeMesageProps {
  group: SubscriptionGroupResource;
  model: SubscriptionModel;
}

const messageWidth = {
	width: '170px'
};

const WelcomeMesage : FC<WelcomeMesageProps> = ({ group, model }) => {
  const { WelcomeMessageFr, WelcomeMessageEn } = model;
  return (
    <MyCard>
      <Table>
        <TableBody>
        { group.welcomeMessageFr && (
            <CaijTableRow label={WelcomeMessageFr.Label} sxTableCell={messageWidth} whiteSpace='pre-wrap'>
              <div>{parse(group.welcomeMessageFr)}</div>              
            </CaijTableRow>
          )
        }
        { group.welcomeMessageEn && (
            <CaijTableRow sxTableRow={tableRow} label={WelcomeMessageEn.Label} sxTableCell={messageWidth} whiteSpace='pre-wrap'>
              <div>{parse(group.welcomeMessageEn)}</div>              
            </CaijTableRow>
          )
        }
        </TableBody>
      </Table>
    </MyCard>
  );
};

export default WelcomeMesage;
